import { mapGetters, mapMutations, mapState } from 'vuex';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
// import { getItem, deleteItem, postItem } from '../../../../api/api-methods';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnTable from '../../../shared/components/KnTable.vue';
import {
  fetchReportCards,
  fetchStudents,
} from '../../helpers/reportCardOptions';
import { getFullName } from '../../../shared/helpers/dataUtils';
import {
  canAdd,
  insufficientPermissionsMessage,
} from '../../../shared/helpers/permissionsUtils';
export default {
  name: 'ReportCardIndex',
  components: { KnTable, KnLocalAlert },
  mixins: [paginationMixin],
  props: {},
  data() {
    return {
      reportCards: [],
      headers: [
        {
          text: 'Nombre boleta',
          value: 'nombre_boleta',
          class: 'purple--text',
        },
        { text: 'Periodo', value: 'periodo', class: 'purple--text' },
        { text: 'Alumno', value: 'alumno', class: 'purple--text' },
        { text: 'Autor', value: 'autor.username', class: 'purple--text' },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones', class: 'purple--text' },
      ],
      items: [],
      inactiveItems: [],
      //  search: null,
      showInactive: false,
      loading: false,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loadingAlert: false,
      showAlert: false,
      alertType: 'info',
      alertText: insufficientPermissionsMessage(),
      alertColor: 'warning',
      /*********************** */
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'search']),
    ...mapGetters(['isBasicEmployee', 'userId']),
    buttomActionText() {
      return this.showInactive
        ? 'Ver Boletas Activas'
        : 'Ver Boletas Inactivas';
    },
    tableTitle() {
      return this.showInactive ? 'Boletas Inactivas' : 'Boletas';
    },
  },
  watch: {
    search: {
      async handler() {
        await this.getPaginatedReportCards(1);
      },
    },
    showInactive: {
      async handler() {
        await this.getPaginatedReportCards(1);
      },
    },
    institutionId: {
      async handler() {
        await this.getPaginatedReportCards(1);
      },
    },
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedReportCards(1);
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    canAdd: canAdd,
    async getPaginatedReportCards(page = 1) {
      this.setPaginationPage(page);
      if (!this.loading) {
        this.items = [];
        this.loading = true;
        const { ok, data, message, count } = await fetchReportCards({
          institutionId: this.institutionId,
          authorId: this.isBasicEmployee ? this.userId : null,
          reportCardName: this.search,
          systemStatus: !this.showInactive,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        });
        if (ok) {
          this.setPaginationCount(count);
          const reportCards = data;
          for (const reportCard of reportCards) {
            // console.log('Ciclo reportCard', reportCard);
            let item = {
              ...reportCard,
              student: null,
              alumno: 'Sin asignar',
            };
            const {
              ok: okStudent,
              data: dataStudent,
              count: countStudent,
              message: messageStudent,
            } = await fetchStudents({
              institutionId: this.institutionId,
              systemStatus: true,
              reportCardIds: reportCard.id,
            });
            if (okStudent && countStudent) {
              item.student = dataStudent[0];
              item.alumno = getFullName(item.student.datos_personales);
            } else {
              console.log(
                'No se encontraron alumnos para la boleta ' + reportCard.id,
                messageStudent
              );
            }
            this.items.push(item);
          }
        } else {
          console.error('Error al obtener boletas. ', message);
        }
        this.loading = false;
      }
    },
  },
};
